import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { SiteService } from 'src/app/services/site.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimerService } from 'src/app/services/timer.service';
import { SettingsService } from '../services/settings.service';
import { ToggleService } from '../services/toggle.service';
import { ViewComponent } from '../library-setting/view/view.component';

@Component({
  selector: 'app-controller-general-info',
  templateUrl: './controller-general-info.component.html',
  styleUrls: ['./controller-general-info.component.scss'],
})
export class ControllerGeneralInfoComponent implements OnInit, OnChanges {
  editing = false;
  controller = {
    dbId: "",
    model: "",
    firmware: "",
    id: "",
    name: "",
    in_use: false,
    thermistor_fail: false,
    updated: null,
    time_log_interval: "N/A",
    controller_time: "",
    on_to_auto: "",
    hc_delay: "",
    direct_reporting: false,
    hub_id: '',
    node_id: '',
    site_id: ""
  }
  settings_name;
  controllerGeneralInfoForm;
  settings_to_import;
  in_use;
  public outputNames = [];
  public outputNameForm;
  public section = 'settings';
  public settingsName = "";
  public scheduleName = "";
  public optionId;
  public libraryOptions = []
  private zones;
  private model;

  @Input() info;
  @Input() user;
  @Input() selected;

  constructor(
    public nodeService: SitesService,
    public siteService: SiteService,
    private timers: TimerService,
    public toastCtrl: ToastController,
    public formBuilder: UntypedFormBuilder,
    private settingsService: SettingsService,
    private alertController: AlertController,
    private toggleService: ToggleService,
    private modalController: ModalController
    ) {
      this.settings_to_import = undefined;      
  }

  ngOnInit() {
    this.nodeService.nodeDataSubscription.subscribe((data) => this.onGetNodeSuccess(data));
    this.nodeService.doUpdates = 2;
    this.timers.setTimerSecond(1);
    if(this.info) {
      this.setFormInfo(this.info);
    }

    this.getLibraryOptionList();
    if(this.selected) { this.section = this.selected; }
  }

  ngOnChanges() {
    if(this.selected) { this.section = this.selected; }
  }

  getLibraryOptionList() {
    this.settingsService.getLibraryOptions(this.controller.site_id)
          .then((data) => {  
            this.libraryOptions = data.filter((d) => d.model === this.model && d.zones === this.zones); 
          })
          .catch((e) => this.libraryOptions = [] )
  }

  canView(toggleName) {
    return this.toggleService.hasAccess(toggleName)
  }

  setFormInfo(info) {
    this.controller.model = info.model;    
    this.controller.firmware = info.fw.split('').map((hex) => parseInt(hex, 16)).join('.');
    this.controller.id = info.node_id;
    this.controller.name = info.api_name || info.node_name;
    this.controller.in_use = ((info.in_use !== undefined || info.in_use !== null) ? info.in_use : true);
    this.controller.hc_delay = info.settings && info.settings.hc_delay;
    this.controller.hub_id = info.hub_id;
    this.controller.direct_reporting = info.direct_reporting;
    this.controller.site_id = info.site_id;
    this.in_use = ((info.in_use !== undefined || info.in_use !== null) ? info.in_use : true);

    if(this.controller.model && !this.controller.model.startsWith("TB") && info.settings) {
      this.controller.thermistor_fail = info.settings ? (this.info.settings.therm_fail !== "OFF") : false;
      this.controller.on_to_auto = info.settings.on2auto;
      this.controller.controller_time = info.settings ? info.settings.controller_time : "0:00";
    }

    this.controller.updated = info.updatedAt;
    this.controller.dbId = info._id;
    this.controller.time_log_interval = info.time_log_interval;

    this.controllerGeneralInfoForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.controller.name, []),
      thermistor_fail: new UntypedFormControl(this.controller.thermistor_fail, []),
      time_log_interval: new UntypedFormControl(this.controller.time_log_interval, []),
      controller_time: new UntypedFormControl(this.controller.controller_time, []),
      on_to_auto: new UntypedFormControl(this.controller.on_to_auto, []),
      hc_delay: new UntypedFormControl(this.controller.hc_delay, [Validators.min(0), Validators.max(30)]),
    });
  }

  onGetNodeSuccess(updatedControllerInfo) {
    this.info = updatedControllerInfo;
    this.zones = updatedControllerInfo.status ? updatedControllerInfo.status.zones : 1;
    this.model = updatedControllerInfo.model;
    this.setFormInfo(updatedControllerInfo)

    let modelToNames = {
      "CB-GHK": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKC": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKCH": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 'h3', default_name:"Heat 3"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKHC": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 'h3', default_name:"Heat 3"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKH": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 'h3', default_name:"Heat 3"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-RWL": [
        {output: 'v1', default_name:"Vent A"}, {output: 'v2', default_name:"Vent B"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-TB": [0,1,2,3,4,5,6,7,8,9,10].map((t) => { return {default_name: `Timer ${t}`, output: `t${t}`}}),
      "TB-TMR": [0,1,2,3,4,5,6,7,8,9,10].map((t) => { return {default_name: `Timer ${t}`, output: `t${t}`}})
    }

    if(modelToNames[this.controller.model]) {
      this.outputNameForm = new UntypedFormGroup(modelToNames[this.controller.model].reduce((all, output) => {
        let default_val = (updatedControllerInfo && updatedControllerInfo.output_overrides) ? updatedControllerInfo.output_overrides[output.output] : '';
        all[output.output] = new UntypedFormControl(default_val, [])
        return all;
      }, {}))
      
      this.outputNames = modelToNames[this.controller.model]
    }
  }

  updateOutputNames(values) {
    this.nodeService.updateOutputNames(this.controller.node_id, values)
        .then(() => { this.showMessage("Your output names were updated"); })
        .catch(() => { this.showMessage("Your output names could not be updated at this time. Pleast try again."); })
  }

  updateSettings(key, value) {
    this.editing = false;

    if(key === 'thermistor_fail') {
      value = value ? 1 : 0;
    }

    var update_doc = {node_id: this.controller.dbId};
    update_doc[key] = value;
    console.log(`updating to: ${JSON.stringify(update_doc)}`)
    this.controllerGeneralInfoForm.reset();
    this.nodeService.updateGeneralSettings(update_doc)
        .then((data) => {
          this.showMessage("Controller settings were sent to the API. Owners and admins can receive notifications when they are successfully applied at the controller.")
        })
        .catch((error) => {
          this.showMessage("Sorry there was an error applying setting updates at this time, please try again soon.")
        });
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top'
    });

    toast.present();
  }

  reformatTime(event, field, recursive?) {
    var timeString = recursive ? event : event.target.value;
    if(recursive || Number.isInteger(parseInt(event.key))) {
      timeString = timeString.replace(":", "")
      let updatedVal;
      if(timeString.length == 1) {
        updatedVal = `${timeString}`;
      } else if(timeString.length == 2) {
        updatedVal = `:${timeString}`
      } else if(timeString.length >= 3 && timeString.length <= 4){
        var startInt = (timeString.length === 3) ? 1 : 2
        updatedVal = `${timeString.substring(0, startInt)}:${timeString.substring(startInt, timeString.length)}`
      } else {
        if((timeString.length % 2) !== 0) {
          timeString = timeString.padStart((timeString.length + 1), '0')
        }

        var time_array = timeString.match(/.{1,2}/g);
        var formatted_string = time_array.map((segment) => {
          return this.reformatTime(segment, field, true)
        }).join("")
        updatedVal = formatted_string.substring(1, formatted_string.length)
      }

      if(recursive) {
        return updatedVal;
      } else {
        var valFormInput = this.controllerGeneralInfoForm.get(field);
        valFormInput.setValue(updatedVal);
      }
    }
  }

  public downloadUrl() {
    return this.nodeService.getDownloadSettingsUrl(this.controller.id);
  }

  private validImportSettingsKeys() {
    return ["lightSource","group1Timer","group1Mister","group2Timer","group2Mister","english","wind","difst","dayst","nitst","difsp1","daysp1","nitsp1","difsp2","daysp2","nitsp2","sp1","sp2","sp3","humsp","on2auto","hc_delay","cisID","controller_time","therm_fail","alarm1l","alarm1h","alarm2l","alarm2h","c1t","c2t","c3t","c4t","h1t","h2t","c1m","c2m","c3m","c4m","h1m","h2m","dhStartTime","dhSp","dhFan1","dhFan2","dhHeat","dhLoTemp","dhHiTemp","dhSetup","dhVentMode","dhDif","dhDay","dhNight","dhRepeat","shadeOpenTime","shadeStep","shadePause","shadeTempSP","shadeEnable","shadeSlowRetract","vent"];
  }

  public loadSettingsFromDevice(event) {
    // console.log(`loadSettingsFromDevice: ${JSON.stringify(event)}`)
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      let data = fileReader.result as string;
      let importing_settings = JSON.parse(data);
      let valid_keys = this.validImportSettingsKeys();
      let to_import = Object.keys(importing_settings).reduce((settingsToImport, key) => {
       if(valid_keys.includes(key)) {
         settingsToImport[key] = importing_settings[key];
       }

       return settingsToImport;
      }, {});

      this.settings_to_import = to_import;
    }

    fileReader.onerror = (error) => {
      console.log(error);
      this.showMessage("Sorry, we were not able to parse that file.")
      this.settings_to_import = undefined;
    }

    // console.log(file);
    fileReader.readAsText(file, "UTF-8");
  }

  public saveImportSettings() {
    if(this.settings_to_import) {
      this.nodeService.importSettings(this.controller.id, this.settings_to_import)
                      .then(() => {
                        this.showMessage("Your settings were imported. Please wait a few minutes for the controller to have time to apply them. ")
                        this.settings_to_import = undefined;
                      })
                      .catch((error) => {
                        this.showMessage("Sorry there was an error applying setting updates at this time, please try again soon.")
                      })
    }
  }

  public saveNotInUse() {
    this.siteService.markNotInUse(this.controller.id, this.in_use)
        .then((response) => {
          this.controller.in_use = ((this.in_use !== undefined || this.in_use !== null) ? this.in_use : true);
          this.showMessage("The controller was updated successfully");
        })
        .catch((error) => {
          this.showMessage("Sorry there was an error applying setting updates at this time, please try again soon.")
        })
  }

  async saveSettingsToLibrary() {
    let callApiToAddSetting = () => {
      try {
        if(this.settingsName && this.settingsName.length > 0 || this.optionId) {
          if(this.scheduleName) {
            this.settingsService.updateLibraryOptions(this.controller.site_id, {
              name: this.settingsName, 
              id: this.controller.node_id, 
              scheduleName: this.scheduleName,
              optionId: this.optionId
            }, "ADD")
              .then((response) => {
                this.getLibraryOptionList();
                this.showMessage(`Your library settings have been saved`);
              })
              .catch((e) => {
                this.showMessage(`Your library settings could not be saved. Please try again later`);
              })
            } else {
              this.showMessage(`Please add a name for this part of the growing plan schedule (e.g. Week 1, Week 2)`);
            }
        } else {
          this.showMessage(`Please select a plan to add the setting to or give this growing plan a new name`);
        }
      } catch(e) {
        console.log(e);
        this.showMessage(`Your library settings could not be saved. Please try again later`);
      }
    }

    let selectedOption = this.libraryOptions.find((option) => option.id === this.optionId)
    let name = this.settingsName || (selectedOption && selectedOption.name)
    const alert = await this.alertController.create({
      header: 'Add Growing Library Entry',
      message: `Are you sure you want to add ${this.scheduleName} to ${name}?`,
      buttons: [
        {
          text: 'No, Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (data) => {}
        }, {
          text: `Add to Library`,
          handler: callApiToAddSetting
        }
      ]
    })

    await alert.present();       
  }

  importLibrarySettings(option, index) {
    this.nodeService.importSettingsFromLibrary(this.controller.node_id, option.id, index)
      .then(() => {
        this.showMessage(`The settions from ${option ? option.name : 'the selected library option'} are being imported now.`);
      })
      .catch(() => {
        this.showMessage(`The settions from ${option ? option.name : 'the selected library option'} could not be imported. Please try again later.`);
      })    
  }

  async viewLibrarySetting(option, index) {
    this.settingsService.getLibraryOptionDetails(this.controller.site_id, option.id, index)
      .then(async (response) => {
        const modal = await this.modalController.create({
          component: ViewComponent,
          componentProps: {
            option: option.schedule[index],
            name: response[0].name,
            settings: response[0].settings
          },
        });
        modal.present();
    
        await modal.onWillDismiss();
      })
      .catch((e) => {
        console.log(`error`)
      })    
  }
}
