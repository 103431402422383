<div outline='true' *ngIf="display"
  (click)="editSetting(param)"
  class="status-chip"
  [class.indicator-on]="indicator && isHeat()"
  [class.off]="showstatus && (!indicator && !pending)"
  [class.status-button-pending]="pending"
  [class.cool-on]="indicator && !isHeat()"
  [class.heat-on]="indicator && isHeat()"
  [class.green-button]="green"
  [class.red-button]="red"
>
  <div class="name" [class.green]="underline && underline === 'green'" [class.red]="underline && underline === 'red'">
    <p class="bold" [class.no-data]="!data">
      {{name}}
      <span *ngIf="indicator && param && param.includes('_float')"><ion-icon name='alert-circle-outline' color="danger"></ion-icon></span>
    </p>    
    <p *ngIf="data" class="data">
      <ngx-gauge *ngIf="(vfdStatus !== undefined && vfdStatus !== null)" type="semi" [value]="vfdStatus" label="Percent Open" size="50" 
        thick="5" min="0" max="100">
      </ngx-gauge>
      <span class="not-bold" *ngIf="!indicator && (param.includes('vent') || activation)">Activation&nbsp;</span>
      <span *ngIf="!activation && !settingIsZero()">{{data}}{{units}}</span>
      <span *ngIf="activation">{{activation}}{{units}}</span>
      <span *ngIf="settingIsZero()">Disabled</span>
    </p>
    <p *ngIf="!data && !green && !red" class="data">Not Set</p>
  </div>
  <div class="status-box"
    [class.indicator-on]="indicator && isHeat()"
    [class.cool-on]="indicator && !isHeat()"
    [class.heat-on]="indicator && isHeat()"
    [class.off]="showstatus && (!indicator && !pending)"
    [class.status-button-pending]="pending"
    *ngIf="showstatus"
  >
    <p class="pending" *ngIf="pending">SYNCING</p>
    <p class="on" *ngIf="!pending && indicator">{{ (setting === "MAN") ? "MANUAL" : (setting ? setting : "N/A")}}</p>
    <p [class.off]="off" *ngIf="showstatus && (!indicator && !pending)">
      {{ (setting === "MAN") ? "MANUAL" : (setting ? setting : "N/A")}}
    </p>    
  </div>
</div>
