import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';

import { WindRainNodeListComponent } from '../wind-rain-node-list/wind-rain-node-list.component';
import { EnvironmentalNodeListComponent } from '../environmental-node-list/environmental-node-list.component';
import { WindRainControllerStatusComponent } from '../wind-rain-controller-status/wind-rain-controller-status.component';
import { ControllerStatusComponent } from '../controller-status/controller-status.component';
import { TimerMisterComponent } from '../controller-status/timer-mister.component';
import { StatusChipComponent } from '../status-chip/status-chip.component';
import { MainLogoComponent } from '../main-logo/main-logo.component';
import { SettingsListPopoverComponent } from '../settings-list-popover/settings-list-popover.component';
import { AuditTrailInnerComponent } from '../audit-trail-inner/audit-trail-inner.component';
import { UsersComponent } from '../users/users.component';
import { ControllerUsersComponent } from '../controller-users/controller-users.component';
import { ControllerNotificationsComponent } from '../controller-notifications/controller-notifications.component';
import { ControllerGeneralInfoComponent } from '../controller-general-info/controller-general-info.component';
import { ControllerStatisticsComponent } from '../controller-statistics/controller-statistics.component';
import { ControllerSearchAndAddComponent } from '../controller-search-and-add/controller-search-and-add.component';
import { ControllerLogsComponent } from '../controller-logs/controller-logs.component';
import { TimeBossStatusComponent } from '../time-boss-status/time-boss-status.component';
import { WaterSensorComponent } from '../water-sensor/water-sensor.component';
import { TimeBossListItemComponent } from '../time-boss-list-item/time-boss-list-item.component';
import { EditTimeBossComponent } from '../edit-time-boss/edit-time-boss.component';
import { UnclaimedControllersComponent } from '../unclaimed-controllers/unclaimed-controllers.component';
import { OfflineControllersComponent } from '../offline-controllers/offline-controllers.component';
import { AuditTrailReportComponent } from '../audit-trail-report/audit-trail-report.component';
import { AdminSubscriptionsComponent } from '../admin-subscriptions/admin-subscriptions.component';
import { LightSensorComponent } from '../light-sensor/light-sensor.component';
import { TimeBossListComponent } from '../time-boss-status/time-boss-list.component';
import { MisterSettingsComponent } from '../mister-settings/mister-settings.component';
import { SiteUsageReportComponent } from '../site-usage-report/site-usage-report.component';
import { RunTimesComponent } from '../run-times/run-times.component';
import { SiteReportsComponent } from '../site-reports/site-reports.component';
import { ShowHidePasswordComponent } from '../show-hide-password/show-hide-password.component';
import { CreditCardInformationComponent } from '../credit-card-information/credit-card-information.component';
import { DehumidificationFormComponent } from '../programming-tools/dehumidification-form/dehumidification-form.component';
import { VentFormComponent } from '../programming-tools/vent-form/vent-form.component';
import { TimerFormComponent } from '../programming-tools/timer-form/timer-form.component';
import { NodeTableComponent } from '../node-table/node-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgrammingToolsComponent } from '../programming-tools/programming-tools.component';
import { ViewComponent } from '../library-setting/view/view.component';
import { TimerGraphComponent } from '../timer-graph/timer-graph.component';
import { TitleNavigationComponent } from '../programming-tools/title-navigation/title-navigation.component';
import { ReviewComponent } from '../programming-tools/review/review.component';
import { NavigationComponent } from '../programming-tools/navigation/navigation.component';
import { HighLowReportAdminComponent } from '../high-low-report-admin/high-low-report-admin.component';
import { AdminSitesComponent } from '../admin-sites/admin-sites.component';
import { ChangeSiteOwnerComponent } from '../change-site-owner/change-site-owner.component';
import { AdminControllersComponent } from '../admin-controllers/admin-controllers.component';
import { NgxGaugeModule } from 'ngx-gauge';


let components = [
  WindRainNodeListComponent,
  EnvironmentalNodeListComponent,
  StatusChipComponent,
  MainLogoComponent,
  TimerMisterComponent,
  ControllerStatusComponent,
  SettingsListPopoverComponent,
  AuditTrailInnerComponent,
  UsersComponent,
  ControllerNotificationsComponent,
  ControllerUsersComponent,
  WindRainControllerStatusComponent,
  ControllerGeneralInfoComponent,
  ControllerStatisticsComponent,
  ControllerLogsComponent,
  TimeBossStatusComponent,
  WaterSensorComponent,
  TimeBossListItemComponent,
  EditTimeBossComponent,
  UnclaimedControllersComponent,
  OfflineControllersComponent,
  LightSensorComponent,
  TimeBossListComponent,
  MisterSettingsComponent,
  AdminSubscriptionsComponent,
  AuditTrailReportComponent,
  SiteUsageReportComponent,
  RunTimesComponent,
  ShowHidePasswordComponent,
  ControllerSearchAndAddComponent,
  CreditCardInformationComponent,
  SiteReportsComponent,
  DehumidificationFormComponent,
  VentFormComponent,
  TimerFormComponent,
  NodeTableComponent,
  ProgrammingToolsComponent,
  ViewComponent,
  TimerGraphComponent,
  TitleNavigationComponent,
  ReviewComponent,
  NavigationComponent,
  HighLowReportAdminComponent,
  AdminSitesComponent,
  ChangeSiteOwnerComponent,
  AdminControllersComponent
]

@NgModule({
    imports: [
        CommonModule,
        IonicModule.forRoot(),
        RouterModule,
        IonicSelectableComponent,
        FormsModule,
        ReactiveFormsModule,
        NgxGaugeModule
    ],
    declarations: components,
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
