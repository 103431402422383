import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { GlobalErrorHandler } from './global-error-handler';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { NgxGaugeModule } from 'ngx-gauge';

import { NativeHttpInterceptor } from './native-http.interceptor';
import { EditSettingPageModule } from './edit-setting/edit-setting.module';
import { EditVentSettingPageModule } from './edit-vent-setting/edit-vent-setting.module';
import { EditHeatCoolSettingPageModule } from './edit-heat-cool-setting/edit-heat-cool-setting.module';
import { WindRainEditPageModule } from './wind-rain-edit/wind-rain-edit.module';
import { DeviceSelectPageModule } from './notifications/device-select/device-select.module';
import { ComponentsModule } from './components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { PasswordStrengthProvider } from 'src/app/services/utility/password-strength';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicPickAngularModule } from 'ionic-pick';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,        
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicSelectableComponent,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        EditSettingPageModule,
        EditVentSettingPageModule,
        EditHeatCoolSettingPageModule,
        WindRainEditPageModule,
        DeviceSelectPageModule,
        NgxGaugeModule,
        IonicPickAngularModule
    ],
    providers: [
        StatusBar,
        BarcodeScanner,
        NativeAudio,
        FirebaseMessaging,
        PasswordStrengthProvider,
        EmailComposer,
        HTTP,
        AppVersion,
        InAppBrowser,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NativeHttpInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
